import '../Styles/detalleSolicitud.css';
import ModalRemove from './ModalRemove';
import { useForm, useFieldArray } from 'react-hook-form';
import { useState, useEffect, useRef, useMemo } from 'react';
// import Viewer from './FileViewer';
import FileViewer from '../../Components/FileViewer';
import FileContainer from './FileContainer';
import SelectAsync from '../../Formulario/Components/selectAsync';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OperationStatus from '../../config/OperationStatus';
import RolesConfig from '../../config/Roles';
import {
  actualizarSolicitud,
  actualizarArchivos,
  cambiarEstadoSolicitudAdm,
  obtenerSolicitudDetalle,
  obtenerCantonesByProvinciaId,
  obtenerDistritosByProvinciaId,
  obtenerProvinciasCostaRica,
  obtenerPersonasSicobatec,
  actualizarCombrobacionSolicitud,
  asignarSolicitud,
  AgregarProrrogaAdm,
  obtenerCurriculumsByCarreraId,
  obtenerBecas,
  obtenerNivelesBecas,
  obtenerPatrocinadores,
  obtenerPatrocinios,
  ArchivosPatrocinios,
  ArchivosBecas,
  eliminarArchivoB,
  ObtenerArchivo,
  agregarArchivoSicobatec,
  eliminarArchivoSicobatec,
  removerProrrogaAdm,
  agregarOtrosDocumentosAdm,
  removerDocumentoAdm,
  actualizarProrrogaAdm,
  ActualizarDocumentoAdministrativo,
  obtenerTiposIngreso,
} from '../../redux/solicitudesAdmSlice';
import { agregarSolicitudInfoDocumento } from '../../redux/solicitudesSlice';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import UploadBtn from '../../Formulario/Components/UploadBtn';
import LoadingSpiner from '../../Components/LoadingSpiner';
import Modal from './Modal';
import WindowDimension from '../../Hooks/WindowDimension';
import MultiFiles from './MultiFiles';
import FileInfo from '../../Components/FileInfo';
import Required from '../../Components/Required';
import DetailMainSection from './DetailMainSection';
import DetailChildSection from './DetailChildSection';
import { convertToBoolean, extractRoles } from '../../Utils/utils';
import useValidarSicobatec from '../Hooks/useValidarSicobatec';
import useSubmit from '../../Hooks/useSubmit';
import DetailHeader from './DetailHeader';

let btnActualizar;

const isObject = (prop) => {
  return typeof prop === 'object' && prop !== null;
};

const ableInputs = () => {
  btnActualizar.current.classList.toggle('invisibleBtn');
};

function DetalleSolicitud(props) {
  const Rol = RolesConfig;
  const { width } = WindowDimension();
  const { usuarioId } = props.usuario;
  const [showFileModalScholarShip, setShowFileModalScholarShip] =
    useState(false);
  const [showFileModalOthers, setShowFileModalOthers] = useState(false);
  const [esBeca, setEsBeca] = useState(false);
  const [modalState, changeModalState] = useState(false);
  const [modalFileDelete, setModalFileDelete] = useState(false);
  const [modalRemoveProrroga, setModalRemoveProrroga] = useState(false);
  const [isProrrogaEdited, setIsProrrogaEdited] = useState(false);
  const [modalFileSicobatecDelete, setModalFileSicobatecDelete] =
    useState(false);
  const [selectedFile, setSelecteFile] = useState(null);
  const [razonEstado, setRazonEstado] = useState('');
  const [becas, setBecas] = useState([]);
  const [patrocinios, setPatrocinios] = useState([]);
  const [patrocinadores, setPatrocinadores] = useState([]);
  const [nivelesBecas, setNivelesBecas] = useState([]);
  const [sesiones, setSesiones] = useState([]);

  const [tipoEstadoSolicitud, cambiarTipoEstadoSolicitud] = useState(3);
  const [loadingFiles, setLoadingFiles] = useState('');
  const [ActualizacionParcial, setActualizacionParcial] = useState(false);
  const [inputsHabilitados, setInputsHabilitados] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [mayoriaEdad, cambiarMayoriaEdad] = useState();
  const [experienciaEducacion, cambiarExperiencia] = useState();
  const [personaSelected, selectPersona] = useState();
  const [carrerasByNiveles, setCarreras] = useState();
  const [showModalProrroga, setShowModalProrroga] = useState(false);
  const [showModalReasignar, setShowModalReasignar] = useState(false);
  const [showModalDocAdministrativo, setShowModalDocAdministrativo] =
    useState(false);
  const [mostrarArchivo, setMostrarArchivo] = useState(false);
  const [docs, setDocs] = useState([]);
  const [editedDoc, setEditedDoc] = useState({});
  const [itemValue, setItemValue] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const roles = extractRoles();
  const costaRicaId = 192;

  const [fileToShow, setFileToShow] = useState({ archivo: '', tipo: '' });
  const [view, setView] = useState(false);

  let navigate = useNavigate();

  const { submitAction, sendingData } = useSubmit();

  btnActualizar = useRef();
  const params = useParams();

  const [provincias, setProvincias] = useState([]);
  const [trabajoProvincias, setTrabajoProvincias] = useState([]);
  const [cantones, setCantones] = useState([]);
  const [trabajoCantones, setTrabajoCantones] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [trabajoDistritos, setTrabajoDistritos] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [solicitudDetalle, setSolicitudDetalle] = useState({});

  const { loadingDetalle, tiposPeriodosIngreso, personasSicobatec, loadingSicobatec, loadingEstado } =
    useSelector((state) => state.solicitudesAdm);

  const {
    register: registerP,
    formState: { errors: errorP },
    handleSubmit: handleSubmitP,
    setValue: setValueP,
    reset: resetP,
  } = useForm();

  const {
    register: registerDoc,
    formState: { errors: errorDoc },
    handleSubmit: handleSubmitDoc,
    setValue: setValueDoc,
    reset: resetDoc,
  } = useForm();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
  } = useForm();

  const { fields, remove } = useFieldArray({
    control,
    name: 'personasIdiomas',
  });

  const { fields: nivelesFields, remove: nivelesRemove } = useFieldArray({
    control,
    name: 'personasEstudios',
  });

  const dispatch = useDispatch();
  const atendidoPor = watch('atendidoPor');
  const financiamientoId = watch('financiamientoId');
  const personaId = watch('personaId');
  const solicitudId = watch('solicitudId');
  const tieneComputadora = watch('tieneComputadora');
  const teniaApoyoAcademico = watch('teniaApoyoAcademico');
  const tieneEnfermedad = watch('tieneEnfermedad');
  const trabaja = watch('tieneTrabajo');
  const personasEstudios = watch('personasEstudios');
  const fechaNacimiento = watch('fechaNacimiento');
  const paisId = watch('paisResidenciaId');
  const provinciaId = watch('provinciaId');
  const cantonId = watch('cantonId');
  const distritoId = watch('distritoId');
  const carreraId = watch('carreraId');
  const curriculumAnio = watch('curriculumAnio');

  const trabajoPaisId = watch('trabajoPaisId');
  const trabajoProvinciaId = watch('trabajoProvinciaId');
  const trabajoCantonId = watch('trabajoCantonId');
  const requiereVerificacionSicobatec = watch('requiereVerificacionSicobatec');
  const periodoId = watch('periodoId');
  const tipoIngresoId = watch('tipoIngresoId');
  const patrocinadorId = watch('patrocinadorId');
  const patrocinioId = watch('patrocinioId');
  const becaId = watch('becaId');
  const anio = watch('anio');
  const sesionId = watch('sesionId');
  const curriculumId = watch('curriculumId');
  const curriculumSesionId = watch('curriculumSesionId');

  const {
    validadoManualmente,
    setValidadoManualmente,
    noAplicaSicobatec,
    setNoAplicaSicobatec,
    verificarDespuesSicobatec,
    setVerificarDespuesSicobatec,
    validarSicobatecManualmente,
  } = useValidarSicobatec(solicitudId);

  const cargarSolicitud = async () => {
    const { payload } = await dispatch(obtenerSolicitudDetalle(params.id));
    setSolicitudDetalle(payload);
  };

  useEffect(() => {
    dispatch(obtenerTiposIngreso());
  }, [dispatch])

  const periodoPrincipal = useMemo(() => {
    return periodos?.find((periodo) => periodo.periodoId === parseInt(periodoId, 10))
  }, [periodoId, periodos])

  const verArchivo = async (archivoId) => {
    setDocs([
      {
        uri: process.env.REACT_APP_BASEURL + 'admin/archivos/File/' + archivoId,
      },
    ]);
    setMostrarArchivo(true);
  };

  const downloadFile = (archivoId, name) => {
    dispatch(ObtenerArchivo({ id: archivoId, name: name })).then(
      ({ payload }) => {
        if (payload.operationStatus === OperationStatus.SUCCESS) {
          toast.success(payload.message);
        } else {
          toast.error(payload.message);
        }
      }
    );
  };

  const actualizarComprobacion = async (e) => {
    e.preventDefault();
    let personaId = personaSelected.personaId;
    let personaSicobatecId = personaSelected.id;

    submitAction(
      actualizarCombrobacionSolicitud,
      { personaId, personaSicobatecId },
      () => {
        dispatch(obtenerPersonasSicobatec(personaId));
        cargarSolicitud();
      }
    );
  };

  const actualizar = async (formData) => {
    let data = Object.assign({}, formData);

    data.personasEstudios.forEach((element) => {
      if (isObject(element.centroEducativoId)) {
        if (typeof element.centroEducativoId.value === 'number') {
          element.centroEducativoId = element.centroEducativoId.value;
          element.centroEducativo = null;
        } else {
          element.centroEducativo = element.centroEducativoId.value;
          element.centroEducativoId = null;
        }
      }
    });
    setLoadingMessage('Actualizando datos...');
    data.tieneEnfermedad = convertToBoolean(data.tieneEnfermedad);
    data.tieneComputadora = convertToBoolean(data.tieneComputadora);
    data.esPortatil = convertToBoolean(data.esPortatil);
    data.TipoIngresoId = parseInt(data.TipoIngresoId ?? "1", 10);

    if (data.becaId == null || data.becaId == '') {
      data.becaId = undefined;
    }
    if (data.levelId == null || data.levelId == '') {
      data.levelId = undefined;
    }
    if (data.patrocinadorId == null || data.patrocinadorId == '') {
      data.patrocinadorId = null;
    }
    if (data.patrocinioId == null || data.patrocinioId == '') {
      data.patrocinioId = null;
    }
    data.sesionId = data.curriculumSesionId;
    let d = { id: data.solicitudId, data: data };
    submitAction(actualizarSolicitud, d, () => {
      cargarSolicitud();
      setInputsHabilitados(false);
      ableInputs();
    });
  };

  const actualizacionParcial = async (data) => {
    data.tieneEnfermedad = convertToBoolean(data.tieneEnfermedad);
    data.tieneComputadora = convertToBoolean(data.tieneComputadora);
    data.esPortatil = convertToBoolean(data.esPortatil);
    let d = { id: data.solicitudId, data: data };

    submitAction(actualizarArchivos, d, cargarSolicitud);
    setLoadingFiles('');
    setActualizacionParcial(false);
  };

  const modalEstadoSolicitud = (state, id) => {
    changeModalState(state);
    cambiarTipoEstadoSolicitud(id);
  };

  const cambiarEstadoSolicitud = async () => {
    if (tipoEstadoSolicitud === 10 && !razonEstado) {
      toast.error('Debe escribir alguna observación');
      return false;
    }
    let d = {
      solicitudId: params.id,
      estadoId: tipoEstadoSolicitud,
      razonEstado: razonEstado,
    };

    submitAction(cambiarEstadoSolicitudAdm, d, () => {
      setRazonEstado('');
      navigate('/Admin/solicitudes');
    });
  };

  const validarMayoriaEdad = () => {
    var fecha = new Date(fechaNacimiento);
    var hoy = new Date();
    fecha.setFullYear(fecha.getFullYear() + 18);
    if (fecha > hoy) {
      cambiarMayoriaEdad(false);
    } else {
      cambiarMayoriaEdad(true);
    }
  };

  const validateCentro = (index) => (e) => {
    let res = false;
    let a = personasEstudios[index];
    if (a.centroEducativo || a.centroEducativoId) {
      res = true;
    }
    return res;
  };

  const cargarcurriculums = async (idCarrera) => {
    if(idCarrera!=undefined &&idCarrera!='' ){
      let { payload } = await dispatch(obtenerCurriculumsByCarreraId(idCarrera));
      
      if (payload?.length > 0) {

          setValue('curriculumAnio', payload[0].anio);
          setCurriculums(payload);
  
      }
    }

  };
  const cargarProvincias = async (trabajo) => {
    let { payload } = await dispatch(obtenerProvinciasCostaRica());
    if (trabajo) setTrabajoProvincias(payload);
    else setProvincias(payload);
  };
  const cargarCantones = async (trabajo, id) => {
    let { payload } = await dispatch(obtenerCantonesByProvinciaId(id));
    if (trabajo) setTrabajoCantones(payload);
    else setCantones(payload);
  };
  const cargarDistritos = async (trabajo, id) => {
    let { payload } = await dispatch(obtenerDistritosByProvinciaId(id));
    if (trabajo) setTrabajoDistritos(payload);
    else setDistritos(payload);
  };

  const handleFileUpload = async (file, itemName) => {
    setLoadingFiles(file.name);
    if (file !== undefined) {
      setActualizacionParcial(true);
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        var fileContent = reader.result;
        setValue(itemName + '.archivoContenido', fileContent);
        setValue(itemName + '.archivoNombre', file.name);
        setValue(itemName + '.tipo', file.type);
        btnActualizar.current.click();
      };
    }
  };

  const handleFilesBecasPatrocinadores = async (data) => {
    var fData = new FormData();
    fData.append('SolicitudId', solicitudId);
    for (var x = 0; x < data.length; x++) {
      fData.append('File', data[x]);
    }

    submitAction(esBeca ? ArchivosBecas : ArchivosPatrocinios, fData, () => {
      cargarSolicitud();
      setShowFileModalScholarShip(false);
    });
  };

  const handleFileUploadTable = async (file, item) => {
    var fData = new FormData();
    fData.append('TipoDocumentoId', item.tipoDocumentoId);
    fData.append('PerfilId', item.perfilId);
    fData.append('SolicitudId', solicitudId);
    fData.append('File', file);

    submitAction(agregarSolicitudInfoDocumento, fData, () => {
      cargarSolicitud();
    });
  };

  const subirArchivoSicobatecManual = async (file) => {
    var fData = new FormData();
    fData.append('SolicitudId', solicitudId);
    fData.append('File', file);

    submitAction(agregarArchivoSicobatec, fData, () => {
      cargarSolicitud();
    });
  };

  const removerArchivoSicobatec = async (data) => {
    submitAction(
      eliminarArchivoSicobatec,
      { archivoId: selectedFile?.archivoId, solicitudId: solicitudId },
      () => {
        setModalFileSicobatecDelete(false);
        cargarSolicitud();
      }
    );
  };

  const getArchivo = (perfilId, documentoId) => {
    return solicitudDetalle.archivos.find(
      (a) =>
        a.perfilId == perfilId &&
        a.documentoId == documentoId &&
        a.solicitudId == solicitudId
    );
  };

  const handleProrrogaClick = (e, item, isEditing = false) => {
    if (isEditing) {
      const prorrogaArray = item.prorroga.split('-');
      const dia = prorrogaArray[0];
      const anio = prorrogaArray[2];
      const mes = prorrogaArray[1];
      setIsProrrogaEdited(true);
      resetP({ fechaHasta: `${anio}-${mes}-${dia}` });
    }
    setValueP('documentoId', solicitudId);
    setValueP('perfilId', item.perfilId);
    setValueP('tipoDocumentoId', item.tipoDocumentoId);

    e.preventDefault();
    setShowModalProrroga(true);
  };

  const abrirModalProrroga = (val) => {
    setSelecteFile(val);
    setModalRemoveProrroga(true);
  };

  const removerProrroga = (e, item) => {
    submitAction(
      removerProrrogaAdm,
      {
        documentoId: solicitudId,
        perfilId: selectedFile?.perfilId,
        tipoDocumentoId: selectedFile?.tipoDocumentoId,
      },
      () => {
        setModalRemoveProrroga(false);
        cargarSolicitud();
      }
    );
  };

  const handleFilesOthers = async (data) => {
    var fData = new FormData();
    fData.append('SolicitudId', solicitudId);
    for (var x = 0; x < data.length; x++) {
      fData.append('File', data[x]);
    }

    submitAction(agregarOtrosDocumentosAdm, { solicitudId, fData }, () => {
      cargarSolicitud();
      setShowFileModalOthers(false);
    });
  };

  const onSubmitP = async (data) => {
    submitAction(
      isProrrogaEdited ? actualizarProrrogaAdm : AgregarProrrogaAdm,
      data,
      () => {
        cargarSolicitud();
        setShowModalProrroga(false);
        resetP({ fechaHasta: null });
        setIsProrrogaEdited(false);
      }
    );
  };
  const SubmitDoc = async (data) => {
    let identifier = itemValue[1];
    let extension = itemValue[3];
    let fullName = identifier + data.nombre + extension;
    let archivoId = editedDoc.archivoId;

    let { payload } = await dispatch(
      ActualizarDocumentoAdministrativo({
        archivoId: archivoId,
        archivoNombre: fullName,
      })
    );
    if (payload.operationStatus === OperationStatus.SUCCESS) {
      toast.success(payload.message);
      setShowModalDocAdministrativo(false);
      resetDoc();
      cargarSolicitud();
    } else {
      toast.error(payload.message);
    }
  };

  const editDocName = (item) => {
    setShowModalDocAdministrativo(true);
    setEditedDoc(item);
    const regexp = /(\d*- Administrativos-\s)(.*)(\.\w+)/g;
    const matches = item.archivoNombre?.matchAll(regexp);

    for (const match of matches) {
      setItemValue(match);
      setValueDoc('nombre', match[2]);
    }
  };

  const handleSubmitU = async () => {
    if (selectedUser == undefined) {
      toast.error(
        'Debe seleccionar el usuario al que reasignará la solicitud '
      );
      return false;
    }
    let d = { solicitudId: solicitudId, usuarioId: selectedUser };

    submitAction(asignarSolicitud, d, () => {
      setShowModalReasignar(false);
      setSelectedUser(undefined);
      cargarSolicitud();
    });
  };

  const showFile = (v, a, t) => {
    setView(v);
    setFileToShow({ archivo: a, tipo: t });
  };

  const handleAnioChange = (e) => {
    let year = e.target.value;
    setValue('becaId', null);
    setValue('levelId', null);
    setValue('periodoId', null);
    setValue('patrocinioId', null);
    let carreraId = watch('carreraId');
    let carrera = solicitudDetalle?.carreras?.find(
      (x) => x.carreraId == carreraId
    );
    let pr = solicitudDetalle?.periodos?.filter(
      (x) => x.tipoPeriodoId == carrera?.tipoPeriodoId
    );

    let p = pr?.filter((x) => parseInt(x.anio) == parseInt(year) || x.periodoId==solicitudDetalle?.periodoId);
    // console.log('pr:',pr,'carrera:',carrera,'periodo:',p,' sera aqui que se cambia ?')
    setPeriodos(p);
  };
  const handleNivelChange = (e) => {
    let nId = e.target.value;
    setValue('carreraId', null);
    setValue('curriculumAnio', 0);
    setValue('curriculumId', null);
    setValue('curriculumSesionId', null);
    setValue('periodoId', null);
    setValue('becaId', null);
    setValue('levelId', null);
    setValue('patrocinioId', null);
    var a = solicitudDetalle?.carreras?.filter((x) => x.nivelId == nId);
    setCarreras(a);
  };
  const handleCarreraChange = (e) => {

    if(e.target.value==solicitudDetalle?.carreraId)
    cargarcurriculums(e.target.value);
    setValue('curriculumId', null);
    setValue('curriculumSesionId', null);
    setValue('periodoId', null);
    setValue('becaId', null);
    setValue('levelId', null);
    setValue('patrocinioId', null);
  };

  const reloadBecas = (e) => {
    setValue('becaId', null);
    setValue('levelId', null);
    setValue('patrocinioId', null);
  };
  const reloadPatrocinio = (e) => {
    setValue('patrocinioId', null);
  };

  const handleBecasChange = (e) => {
    setValue('levelId', null);
  };

  useEffect(() => {
    setValue('sesionId', curriculumSesionId);
  }, [curriculumSesionId]);

  useEffect(() => {
    if (patrocinadores.length == 0) {
      dispatch(obtenerPatrocinadores()).then((res) => {
        if (Array.isArray(res.payload)) setPatrocinadores(res.payload);
        else setPatrocinadores([]);
      });
    }
  }, [patrocinadores]);

  useEffect(() => {
    cargarSolicitud();
  }, []);

  // useEffect(() => {
  //   if (curriculums?.length > 0) {
  //     setValue('curriculumAnio', curriculums[0].anio);
  //   }
  // }, [curriculums]);

  useEffect(() => {
    if (Object.entries(solicitudDetalle).length !== 0) {
      reset(solicitudDetalle);
      let carrera = solicitudDetalle?.carreras?.find(
        (x) => x.carreraId == solicitudDetalle?.carreraId
      );
      setPeriodos(
        solicitudDetalle?.periodos?.filter(
          (x) => x.tipoPeriodoId == carrera?.tipoPeriodoId
        )
      );
      if (carrera?.tipoSesion === 0) {
        setSesiones(solicitudDetalle?.sesiones);
      } else {
        setSesiones(
          solicitudDetalle?.sesiones?.filter(
            (x) => x.sesionId == carrera?.tipoSesion
          )
        );
      }
      setCurriculums(solicitudDetalle?.curriculums);

      setCarreras(
        solicitudDetalle?.carreras?.filter(
          (x) => x.nivelId == solicitudDetalle?.nivelId
        )
      );

      if (solicitudDetalle?.becas != null) {
        setBecas(solicitudDetalle?.becas);
        setNivelesBecas(solicitudDetalle?.levels);
      }
      if (solicitudDetalle?.patrocinadores != null) {
        setPatrocinadores(solicitudDetalle?.patrocinadores);
      }

      if (solicitudDetalle?.patrocinios?.length > 0) {
        setPatrocinios(solicitudDetalle?.patrocinios);
      }
      // else {
      //   console.log('hola');
      //   let s = solicitudDetalle?.sesiones?.find(
      //     (x) => x.sesionId == solicitudDetalle?.sesionId
      //   )?.sesionNombre;
      //   dispatch(
      //     obtenerPatrocinios({
      //       periodo: solicitudDetalle?.periodoId,
      //       anio: solicitudDetalle?.anio,
      //       patrocinador: solicitudDetalle?.patrocinadorId,
      //       session: s,
      //     })
      //   ).then((res) => {
      //     if (Array.isArray(res.payload)) {
      //       console.log('toy aqui', res.payload);
      //       setPatrocinios(res.payload);
      //     } else setPatrocinios([]);
      //   });
      // }

      cambiarMayoriaEdad(solicitudDetalle.esMayor);

      cambiarExperiencia(solicitudDetalle?.experienciaEducacionVirtual);
      reset(solicitudDetalle);
    }
  }, [solicitudDetalle]);

  useEffect(() => {
    if (personaId != undefined) dispatch(obtenerPersonasSicobatec(personaId));
  }, [personaId]);
  useEffect(() => {
    if (Object.entries(solicitudDetalle).length !== 0) {
      setValidadoManualmente(solicitudDetalle?.validadoManualmenteEnSicobatec);
      setVerificarDespuesSicobatec(solicitudDetalle?.verificarDespuesSicobatec);
      setNoAplicaSicobatec(solicitudDetalle?.noAplicaSicobatec);
    }
  }, [solicitudDetalle?.validadoManualmenteEnSicobatec]);

  useEffect(() => {
    if (Object.entries(solicitudDetalle).length > 0) {
      if (solicitudDetalle?.sesiones?.length > 0) {
        let p = periodoId;

        let s = solicitudDetalle?.sesiones?.find(
          (x) => x.sesionId == sesionId
        )?.codigo;

        if (p && s && anio) {
          dispatch(obtenerBecas({ periodo: p, session: s, anio: anio })).then(
            (res) => {
              if (Array.isArray(res.payload)) setBecas(res.payload);
              else setBecas([]);
            }
          );
        }
      }
      if (periodoId && anio && patrocinadorId && sesionId) {
        let s = solicitudDetalle?.sesiones?.find(
          (x) => x.sesionId == sesionId
        )?.sesionNombre;
        dispatch(
          obtenerPatrocinios({
            periodo: periodoId,
            anio: anio,
            patrocinador: patrocinadorId,
            session: s,
          })
        ).then((res) => {
          if (Array.isArray(res.payload)) {
            setPatrocinios(res.payload);
          } else setPatrocinios([]);
        });
      }
    }
  }, [periodoId, anio, sesionId, patrocinadorId]);

  useEffect(() => {
    if (becaId != undefined && becaId != '') {
      dispatch(obtenerNivelesBecas(becaId)).then((res) => {
        if (Array.isArray(res.payload)) setNivelesBecas(res.payload);
        else setNivelesBecas([]);
      });
    }
  }, [becaId]);

  useEffect(() => {
    personasSicobatec?.forEach((item) => {
      if (item.coincide) {
        selectPersona(item);
      }
    });
  }, [personasSicobatec]);

  useEffect(() => {

    if (carreraId!=solicitudDetalle?.carreraId && solicitudDetalle?.carreraId|| curriculumAnio==null ) {
      cargarcurriculums(carreraId);

      let carrera = solicitudDetalle?.carreras?.find(
        (x) => x.carreraId == carreraId
      );

      setPeriodos(
        solicitudDetalle?.periodos?.filter(
          (x) => x.tipoPeriodoId == carrera?.tipoPeriodoId
        )
      );

      let sessions = solicitudDetalle?.sesiones;
      if (carrera?.tipoSesion === 0) {
        setSesiones(sessions);
      } else {
        setSesiones(sessions?.filter((x) => x.sesionId == carrera?.tipoSesion));
      }
    }
  }, [carreraId]);

  useEffect(() => {
    if (paisId) {
      if (paisId == costaRicaId) {
        cargarProvincias(false);
      } else {
        setValue('provinciaId', null);
        setValue('cantonId', null);
        setValue('distritoId', null);
      }
    }
  }, [paisId]);

  useEffect(() => {
    if (trabajoPaisId) {
      if (trabajoPaisId == costaRicaId) cargarProvincias(true);
      else {
        setValue('trabajoProvinciaId', null);
        setValue('trabajoCantonId', null);
        setValue('trabajoDistritoId', null);
      }
    }
  }, [trabajoPaisId]);

  useEffect(() => {
    if (provinciaId) {
      cargarCantones(false, provinciaId);
    }
  }, [provinciaId]);

  useEffect(() => {
    if (trabajoProvinciaId) cargarCantones(true, trabajoProvinciaId);
  }, [trabajoProvinciaId]);

  useEffect(() => {
    if (cantonId) cargarDistritos(false, cantonId);
  }, [cantonId]);

  useEffect(() => {
    if (trabajoCantonId) cargarDistritos(true, trabajoCantonId);
  }, [trabajoCantonId]);

  const validarAnio = (val) => {
    let year = new Date().getFullYear();
    return val >= year ? true : 'El año no puede ser menor al actual';
  };
  const confirmarEliminarArchivo = (val, type = 'beca') => {
    let generalFile;
    if (type === 'general') {
      generalFile = {
        id: val.archivoId,
        archivoId: val.archivoId,
        isGeneral: true,
      };
      setSelecteFile(generalFile);
    } else {
      setSelecteFile(val);
    }
    setModalFileDelete(true);
  };

  const confirmarEliminarArchivoSicobatec = (val) => {
    setModalFileSicobatecDelete(true);
    setSelecteFile(val);
  };
  const eliminarArchivo = async () => {
    submitAction(
      selectedFile.isGeneral ? removerDocumentoAdm : eliminarArchivoB,
      selectedFile.isGeneral
        ? { SolicitudId: solicitudId, ArchivoId: selectedFile.id }
        : selectedFile.id,
      () => {
        setModalFileDelete(false);
        cargarSolicitud();
      }
    );
  };

  return (
    <>
      {/* {view ? (
        <Viewer
          file={fileToShow.archivo}
          type={fileToShow.tipo}
          handleClose={(e) => {
            showFile(false, null, null);
          }}
        />
      ) : (
        <></>
      )} */}

      <main className="mainContentDetail">
        <DetailHeader
          solicitudDetalle={solicitudDetalle}
          roles={roles}
          atendidoPor={atendidoPor}
          setShowModalReasignar={setShowModalReasignar}
          usuarioId={usuarioId}
          modalEstadoSolicitud={modalEstadoSolicitud}
          ableInputs={ableInputs}
          setInputsHabilitados={setInputsHabilitados}
          inputsHabilitados={inputsHabilitados}
          btnActualizar={btnActualizar}
          ActualizacionParcial={ActualizacionParcial}
          handleSubmit={handleSubmit}
          actualizacionParcial={actualizacionParcial}
          actualizar={actualizar}
        />

        <ModalRemove
          estado={modalState}
          closeModalHandled={() => changeModalState(false)}
          title={
            tipoEstadoSolicitud === 18
              ? `Preaprobar solicitud no. ${solicitudDetalle?.numero}`
              : tipoEstadoSolicitud === 19
              ? `Aprobada por director`
              : tipoEstadoSolicitud === 10
              ? `Aprobar solicitud no. ${solicitudDetalle?.numero}`
              : tipoEstadoSolicitud === 17 || tipoEstadoSolicitud === 20
              ? `Devolver solicitud no. ${solicitudDetalle?.numero}`
              : `Rechazar solicitud no. ${solicitudDetalle?.numero}`
          }
          message={
            tipoEstadoSolicitud === 18
              ? `¿Desea preaprobar la solicitud realizada por ${solicitudDetalle?.nombres} ${solicitudDetalle?.primerApellido} para la carrera ${solicitudDetalle?.carreraNombre}? `
              : tipoEstadoSolicitud === 19
              ? `¿Desea cambiar el estado de la solicitud no. ${solicitudDetalle?.numero} a aprobada por el director? `
              : tipoEstadoSolicitud === 10
              ? `¿Desea aprobar la solicitud realizada por ${solicitudDetalle?.nombres} ${solicitudDetalle?.primerApellido} para la carrera ${solicitudDetalle?.carreraNombre}? `
              : tipoEstadoSolicitud === 17 || tipoEstadoSolicitud === 20
              ? `¿Desea devolver la solicitud realizada por ${solicitudDetalle?.nombres} ${solicitudDetalle?.primerApellido} para la carrera ${solicitudDetalle?.carreraNombre}?`
              : `¿Desea rechazar la solicitud realizada por ${solicitudDetalle?.nombres} ${solicitudDetalle?.primerApellido} para la carrera ${solicitudDetalle?.carreraNombre}?`
          }
          btnGroup={
            <>
              <button
                type="button"
                className="button-text text-red"
                onClick={cambiarEstadoSolicitud}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="button-text text-grey"
                onClick={() => {
                  changeModalState(false);
                }}
              >
                Cancelar
              </button>
            </>
          }
          icon={null}
        >
          <>
            <div className="form-group mt-2 min-width">
              <label>Observaciones </label>
              <textarea
                className="form-control"
                type="text"
                value={razonEstado}
                onInput={(e) => setRazonEstado(e.target.value)}
              ></textarea>
            </div>
            {loadingEstado && (
              <div>
                <LoadingSpiner
                  styles={{
                    width: '45px',
                    height: '45px',
                    border: '6px solid #bbbbbb',
                    borderTop: '6px solid #035cc9',
                  }}
                />
              </div>
            )}
          </>
        </ModalRemove>

        <ModalRemove
          estado={modalFileDelete}
          closeModalHandled={() => setModalFileDelete(false)}
          title={'Eliminar archivo'}
          clase={'small'}
          message={`¿Está seguro(a) de eliminar el archivo no. ${selectedFile?.archivoId}?`}
          btnGroup={
            <>
              <button
                type="button"
                className="button-text text-red"
                onClick={eliminarArchivo}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="button-text text-grey"
                onClick={() => {
                  setModalFileDelete(false);
                }}
              >
                Cancelar
              </button>
            </>
          }
          icon={null}
        />
        <ModalRemove
          estado={modalFileSicobatecDelete}
          closeModalHandled={() => setModalFileSicobatecDelete(false)}
          title={'Eliminar archivo'}
          clase={'small'}
          message={
            'Esta seguro de eliminar el archivo no. ' + selectedFile?.archivoId
          }
          btnGroup={
            <>
              <button
                type="button"
                className="button-text text-red"
                onClick={removerArchivoSicobatec}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="button-text text-grey"
                onClick={() => {
                  setModalFileSicobatecDelete(false);
                }}
              >
                Cancelar
              </button>
            </>
          }
          icon={null}
        />
        <ModalRemove
          estado={modalRemoveProrroga}
          closeModalHandled={() => setModalRemoveProrroga(false)}
          title={'Eliminar prórroga'}
          clase={'small'}
          message={`Esta seguro de eliminar la prórroga para el documento no. ${selectedFile?.documentoId}?`}
          btnGroup={
            <>
              <button
                type="button"
                className="button-text text-red"
                onClick={removerProrroga}
              >
                Confirmar
              </button>
              <button
                type="button"
                className="button-text text-grey"
                onClick={() => {
                  setModalRemoveProrroga(false);
                }}
              >
                Cancelar
              </button>
            </>
          }
          icon={null}
        />

        <Modal
          estado={showModalProrroga}
          cambiarEstado={() => {
            setShowModalProrroga(false);
          }}
          titulo={'Otorgar prórroga'}
          clase={`${width < 576 ? 'full-width' : 'small'}`}
          buttonGroup={
            <>
              <button
                type="button"
                className="button-text"
                onClick={handleSubmitP(onSubmitP)}
              >
                Guardar
              </button>
            </>
          }
        >
          <form>
            <div className="form-group">
              <label>Fecha límite</label>
              <Required />
              <input
                className="form-control"
                type="date"
                {...registerP('fechaHasta', { required: 'Campo requerido' })}
              />
              {errorP.fechaHasta && (
                <p className="error">{errorP.fechaHasta.message}</p>
              )}
            </div>
          </form>
        </Modal>
        <Modal
          estado={showModalReasignar}
          cambiarEstado={() => {
            setShowModalReasignar(false);
          }}
          titulo={'Reasignar solicitud'}
          clase={`${width < 576 ? 'full-width' : 'small'}`}
          buttonGroup={
            <>
              <button
                type="button"
                className="button-text"
                onClick={handleSubmitU}
              >
                Guardar
              </button>
            </>
          }
        >
          <form>
            <div className="form-group">
              <label>Reasignar a:</label>
              <Required />
              <select
                className="form-control"
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                }}
              >
                <option value={undefined}>Seleccione</option>
                {solicitudDetalle?.usuarios
                  ?.filter((x) => x.usuarioId != usuarioId)
                  ?.map((item, i) => {
                    return (
                      <option key={i} value={item.usuarioId}>
                        {item.nombreCompleto}
                      </option>
                    );
                  })}
              </select>
            </div>
          </form>
        </Modal>

        {/* Modal para editar nombre de archivos administrativos */}
        <Modal
          estado={showModalDocAdministrativo}
          cambiarEstado={() => {
            setShowModalDocAdministrativo(false);
          }}
          titulo={'Editar archivo'}
          clase={`${width < 576 ? 'full-width' : 'small'}`}
          buttonGroup={
            <>
              <button
                type="button"
                className="button-text"
                onClick={handleSubmitDoc(SubmitDoc)}
              >
                Guardar
              </button>
            </>
          }
        >
          <form>
            <div className="form-group">
              <label>Nombre:</label>
              <Required />
              <input
                type="text"
                className="form-control"
                {...registerDoc('nombre', {
                  required: 'Campo requerido',
                })}
              />
              {errorDoc.nombre && (
                <p className="error">{errorDoc.nombre.message}</p>
              )}
            </div>
          </form>
        </Modal>

        <MultiFiles
          showFileModal={showFileModalScholarShip || showFileModalOthers}
          title={'Archivos'}
          onPressCancel={() => {
            setShowFileModalScholarShip(false);
            setShowFileModalOthers(false);
          }}
          guardarArchivos={(val) =>
            showFileModalOthers
              ? handleFilesOthers(val)
              : handleFilesBecasPatrocinadores(val)
          }
        />

        <LoadingSpiner
          message={loadingMessage}
          show={loadingDetalle || sendingData}
          fullPage={true}
          styles={{
            top: '45%',
            left: '50%',
            position: 'inherit',
            width: '50px',
            height: '50px',
            borderWidth: '10px',
            borderStyle: 'solid',
            borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
            borderImage: 'initial',
          }}
        />
        <FileViewer
          estado={mostrarArchivo}
          cambiarEstado={() => {
            setMostrarArchivo(false);
          }}
          docs={docs}
        />
        <form onSubmit={handleSubmit()}>
          <div className="content">
            <DetailMainSection title="Datos del solicitante">
              <DetailChildSection title="Datos personales">
                <div>
                  <label>Tipo de identificación</label>
                  <select
                    ref={register}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('tipoDniId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value={''}>
                      Seleccione
                    </option>
                    {solicitudDetalle?.tiposDni?.map((item, i) => {
                      return (
                        <option key={i} value={item.tipoDniId}>
                          {item.tipoDniNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.tipoDniId && (
                    <p className="error">{errors.tipoDniId.message}</p>
                  )}
                </div>
                <div>
                  <label>No. de identificación</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('dni', {
                      required: 'Campo requerido',
                    })}
                    name="dni"
                  />
                  {errors.dni && <p className="error">{errors.dni.message}</p>}
                </div>
                <div>
                  <label>Nombre(s)</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('nombres', {
                      required: 'Campo requerido',
                    })}
                    name="nombres"
                  />
                  {errors.nombres && (
                    <p className="error">{errors.nombres.message}</p>
                  )}
                </div>
                <div>
                  <label>Primer apellido</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('primerApellido', {
                      required: 'Campo requerido',
                    })}
                    name="primerApellido"
                  />
                  {errors.primerApellido && (
                    <p className="error">{errors.primerApellido.message}</p>
                  )}
                </div>
                <div>
                  <label>Segundo apellido</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('segundoApellido', {
                      required: 'Campo requerido',
                    })}
                    name="segundoApellido"
                  />
                  {errors.segundoApellido && (
                    <p className="error">{errors.segundoApellido.message}</p>
                  )}
                </div>
                <div>
                  <label>Fecha de nacimiento</label>
                  <input
                    type="date"
                    onSelect={validarMayoriaEdad}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('fechaNacimiento', {
                      required: 'Campo requerido',
                    })}
                    name="fechaNacimiento"
                  />
                  {errors.fechaNacimiento && (
                    <p className="error">{errors.fechaNacimiento.message}</p>
                  )}
                </div>

                {solicitudDetalle?.fotografia != null && (
                  <div>
                    <label>Fotografía</label>
                    <FileContainer
                      loading={loadingFiles}
                      verArchivo={() => {
                        verArchivo(solicitudDetalle?.fotografia?.archivoId);
                      }}
                      decargarArchivo={() => {
                        downloadFile(
                          solicitudDetalle?.fotografia?.archivoId,
                          solicitudDetalle?.fotografia?.archivoNombre
                        );
                      }}
                      handleFile={(el) => {
                        handleFileUpload(el, 'fotografia');
                      }}
                    />
                  </div>
                )}

                <div>
                  <label>País de residencia</label>
                  <select
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('paisResidenciaId', {
                      required: 'Campo requerido',
                    })}
                    name="paisResidenciaId"
                  >
                    {solicitudDetalle?.paises?.map((item, i) => {
                      return (
                        <option key={i} value={item.paisId}>
                          {item.paisNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.paisResidenciaId && (
                    <p className="error">{errors.paisResidenciaId.message}</p>
                  )}
                </div>
                <div>
                  <label>País de procedencia</label>
                  <select
                    ref={register}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    name="paisProcedenciaId"
                    {...register('paisProcedenciaId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.paises?.map((item, i) => {
                      return (
                        <option key={i} value={item.paisId}>
                          {item.paisNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.paisProcedenciaId && (
                    <p className="error">{errors.paisProcedenciaId.message}</p>
                  )}
                </div>
                <div>
                  <label>Género</label>
                  <select
                    ref={register}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    name="generoId"
                    {...register('generoId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.generos?.map((item, i) => {
                      return (
                        <option key={i} value={item.generoId}>
                          {item.generoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.generoId && (
                    <p className="error">{errors.generoId.message}</p>
                  )}
                </div>
                <div>
                  <label>Estado civil</label>
                  <select
                    ref={register}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    name="estadoCivilId"
                    {...register('estadoCivilId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.estadosCiviles?.map((item, i) => {
                      return (
                        <option key={i} value={item.estadoCivilId}>
                          {item.estadoCivilNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.estadoCivilId && (
                    <p className="error">{errors.estadoCivilId.message}</p>
                  )}
                </div>

                <div>
                  <label>Pasatiempos</label>
                  <div className="divPasatiempos">
                    {solicitudDetalle?.listaPasatiempos?.map((item, index) => (
                      <div
                        className="form-control-check "
                        key={item.pasatiempoId}
                      >
                        <input
                          value={item.pasatiempoNombre}
                          className={
                            inputsHabilitados == true ? '' : 'disabled'
                          }
                          ref={register}
                          {...register('pasaTiempos')}
                          type="checkbox"
                        />
                        <label>{item.pasatiempoNombre}</label>
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <label>Otro pasatiempo</label>
                    <input
                      className={inputsHabilitados == true ? '' : 'disabled'}
                      ref={register}
                      {...register('pasaTiemposOtro')}
                      type="text"
                      placeholder="Especifique"
                    />
                  </div>
                </div>
              </DetailChildSection>

              <DetailChildSection title="Datos de contacto">
                <div>
                  <label>Correo electrónico</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('correoElectronico', {
                      required: 'Campo requerido',
                    })}
                    name="correoElectronico"
                  />
                  {errors.correoElectronico && (
                    <p className="error">{errors.correoElectronico.message}</p>
                  )}
                </div>
                <div>
                  <label>No. de teléfono</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('telefono', {
                      required: 'Campo requerido',
                    })}
                    name="telefono"
                  />
                  {errors.telefono && (
                    <p className="error">{errors.telefono.message}</p>
                  )}
                </div>
                {paisId == costaRicaId ? (
                  <>
                    <div>
                      <label>Provincia de residencia</label>
                      <select
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        ref={register}
                        {...register('provinciaId', {
                          required: 'Campo requerido',
                        })}
                        value={provinciaId}
                      >
                        <option hidden value="">
                          Seleccione
                        </option>
                        {provincias?.map((item, i) => {
                          return (
                            <option key={i} value={item.provinciaId}>
                              {item.provinciaNombre}
                            </option>
                          );
                        })}
                      </select>
                      {errors.provinciaId && (
                        <p className="error">{errors.provinciaId.message}</p>
                      )}
                    </div>
                    <div>
                      <label>Cantón de residencia</label>
                      <select
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        name="cantonId"
                        ref={register}
                        {...register('cantonId', {
                          required: 'Campo requerido',
                        })}
                        value={cantonId}
                      >
                        <option hidden value="">
                          Seleccione
                        </option>
                        {cantones?.map((item, i) => {
                          return (
                            <option key={i} value={item.cantonId}>
                              {item.cantonNombre}
                            </option>
                          );
                        })}
                      </select>
                      {errors.cantonId && (
                        <p className="error">{errors.cantonId.message}</p>
                      )}
                    </div>
                    <div>
                      <label>Distrito de residencia</label>
                      <select
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        ref={register}
                        {...register('distritoId', {
                          required: 'Campo requerido',
                        })}
                        value={distritoId}
                      >
                        <option hidden value="">
                          Seleccione
                        </option>
                        {distritos?.map((item, i) => {
                          return (
                            <option key={i} value={item.distritoId}>
                              {item.distritoNombre}
                            </option>
                          );
                        })}
                      </select>
                      {errors.distritoId && (
                        <p className="error">{errors.distritoId.message}</p>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div>
                  <label>Dirección exacta</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('direccion', {
                      required: 'Campo requerido',
                    })}
                    name="direccion"
                  />
                  {errors.direccion && (
                    <p className="error">{errors.direccion.message}</p>
                  )}
                </div>
              </DetailChildSection>

              <DetailChildSection title="Datos médicos">
                <div>
                  <label>¿Padece de alguna enfermedad?</label>
                  <div className="check-container flex">
                    <div className="form-control-check flex">
                      <input
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        type="radio"
                        {...register('tieneEnfermedad')}
                        value={true}
                        checked={convertToBoolean(tieneEnfermedad) === true}
                      />
                      <label>Si</label>
                    </div>
                    <div className="form-control-check flex">
                      <input
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        type="radio"
                        {...register('tieneEnfermedad')}
                        value={false}
                        checked={convertToBoolean(tieneEnfermedad) === false}
                      />
                      <label>No</label>
                    </div>
                    {convertToBoolean(tieneEnfermedad) == true && (
                      <>
                        <input
                          name="enfermedadNombre"
                          className={
                            convertToBoolean(solicitudDetalle?.tieneEnfermedad)
                              ? 'disabled'
                              : ''
                          }
                          placeholder="Especifique"
                          disabled={convertToBoolean(tieneEnfermedad) != true}
                          type="text"
                          {...register('enfermedadNombre', {
                            required: 'Campo requerido',
                          })}
                        />
                        {errors.tieneEnfermedad && (
                          <p className="error">
                            {errors.tieneEnfermedad.message}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {convertToBoolean(tieneEnfermedad) == true && (
                  <div>
                    <label>Medicamentos que toma</label>
                    <input
                      type="text"
                      className={inputsHabilitados == true ? '' : 'disabled'}
                      {...register('medicamentos')}
                      name="medicamentos"
                    />
                    {errors.medicamentos && (
                      <p className="error">{errors.medicamentos.message}</p>
                    )}
                  </div>
                )}

                <div>
                  <label>Contacto de emergencia</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('contactoEmergenciaNombre', {
                      required: 'Campo requerido',
                    })}
                    name="contactoEmergenciaNombre"
                  />
                  {errors.contactoEmergenciaNombre && (
                    <p className="error">
                      {errors.contactoEmergenciaNombre.message}
                    </p>
                  )}
                </div>
                <div>
                  <label>No. de teléfono del contacto</label>
                  <input
                    type="text"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('contactoEmergenciaTelefono', {
                      required: 'Campo requerido',
                    })}
                    name="contactoEmergenciaTelefono"
                  />
                  {errors.contactoEmergenciaTelefono && (
                    <p className="error">
                      {errors.contactoEmergenciaTelefono.message}
                    </p>
                  )}
                </div>
              </DetailChildSection>

              <DetailChildSection title="Datos financieros">
                <div>
                  <label>¿Cómo financiará sus estudios?</label>
                  <select
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('financiamientoId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.financiamientosTipos?.map((item, i) => {
                      return (
                        <option key={i} value={item.financiamientoId}>
                          {item.financiamientoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {financiamientoId == 3 && (
                    <>
                      <input
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        type="text"
                        id="inputEspecFinance"
                        placeholder="Especifique (opción Otro)"
                        {...register('financiamientoNombre')}
                      />
                      {errors?.financiamientoNombre?.type === 'validate' && (
                        <p className="error">Campo requerido</p>
                      )}
                    </>
                  )}

                  {errors.financiamientoId && (
                    <p className="error">{errors.financiamientoId.message}</p>
                  )}
                </div>
                <div>
                  <label>Posee computadora</label>
                  <input
                    type="checkbox"
                    checked={convertToBoolean(tieneComputadora) === true}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('tieneComputadora')}
                    name="tieneComputadora"
                  />
                </div>
                {tieneComputadora == true && (
                  <div>
                    <label>Posee computadora portátil</label>
                    <input
                      type="checkbox"
                      defaultChecked={
                        convertToBoolean(solicitudDetalle?.esPortatil) === true
                      }
                      className={inputsHabilitados == true ? '' : 'disabled'}
                      {...register('esPortatil')}
                      name="esPortatil"
                    />
                  </div>
                )}
                <div>
                  <label>Trabaja</label>
                  <input
                    type="checkbox"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('tieneTrabajo')}
                    name="tieneTrabajo"
                  />
                </div>
                {trabaja === true ? (
                  <>
                    <div>
                      <label>Trabajo en sector informática</label>
                      <input
                        type="radio"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        {...register('trabajaEnSectorInformatica')}
                        name="trabajaEnSectorInformatica"
                      />
                    </div>
                    <div>
                      <label>Empresa</label>
                      <input
                        type="text"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        {...register('empresaTrabajoNombre', {
                          required: 'Campo requerido',
                        })}
                        name="empresaTrabajoNombre"
                      />
                      {errors.empresaTrabajoNombre && (
                        <p className="error">
                          {errors.empresaTrabajoNombre.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label>Ocupación</label>
                      <input
                        type="text"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        {...register('trabajoOcupacion', {
                          required: 'Campo requerido',
                        })}
                        name="trabajoOcupacion"
                      />
                      {errors.trabajoOcupacion && (
                        <p className="error">
                          {errors.trabajoOcupacion.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label>Dirección exacta de la empresa</label>
                      <input
                        type="text"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        {...register('trabajoDireccion', {
                          required: 'Campo requerido',
                        })}
                        name="trabajoDireccion"
                      />
                      {errors.trabajoDireccion && (
                        <p className="error">
                          {errors.trabajoDireccion.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label>País de la empresa</label>
                      <select
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        {...register('trabajoPaisId', {
                          required: 'Campo requerido',
                        })}
                      >
                        <option hidden value="">
                          Seleccione
                        </option>
                        {solicitudDetalle?.paises?.map((item, i) => {
                          return (
                            <option key={i} value={item.paisId}>
                              {item.paisNombre}
                            </option>
                          );
                        })}
                      </select>
                      {errors.trabajoPaisId && (
                        <p className="error">{errors.trabajoPaisId.message}</p>
                      )}
                    </div>
                    {trabajoPaisId == costaRicaId && (
                      <>
                        <div>
                          <label>Provincia</label>
                          <select
                            className={
                              inputsHabilitados == true ? '' : 'disabled'
                            }
                            {...register('trabajoProvinciaId', {
                              required: 'Campo requerido',
                            })}
                          >
                            <option hidden value="">
                              Seleccione
                            </option>
                            {trabajoProvincias?.map((item, i) => {
                              return (
                                <option key={i} value={item.provinciaId}>
                                  {item.provinciaNombre}
                                </option>
                              );
                            })}
                          </select>
                          {errors.trabajoProvinciaId && (
                            <p className="error">
                              {errors.trabajoProvinciaId.message}
                            </p>
                          )}
                        </div>
                        <div>
                          <label>Cantón</label>
                          <select
                            className={
                              inputsHabilitados == true ? '' : 'disabled'
                            }
                            {...register('trabajoCantonId', {
                              required: 'Campo requerido',
                            })}
                          >
                            <option hidden value="">
                              Seleccione
                            </option>
                            {trabajoCantones?.map((item, i) => {
                              return (
                                <option key={i} value={item.cantonId}>
                                  {item.cantonNombre}
                                </option>
                              );
                            })}
                          </select>
                          {errors.trabajoCantonId && (
                            <p className="error">
                              {errors.trabajoCantonId.message}
                            </p>
                          )}
                          {errors?.trabajoCantonId?.type === 'validate' && (
                            <p className="error">Campo requerido</p>
                          )}
                        </div>
                        <div>
                          <label>Distrito</label>
                          <select
                            className={
                              inputsHabilitados == true ? '' : 'disabled'
                            }
                            {...register('trabajoDistritoId', {
                              required: 'Campo requerido',
                            })}
                          >
                            <option hidden value="">
                              Seleccione
                            </option>
                            {trabajoDistritos?.map((item, i) => {
                              return (
                                <option key={i} value={item.distritoId}>
                                  {item.distritoNombre}
                                </option>
                              );
                            })}
                          </select>
                          {errors.trabajoDistritoId && (
                            <p className="error">
                              {errors.trabajoDistritoId.message}
                            </p>
                          )}
                          {errors?.trabajoDistritoId?.type === 'validate' && (
                            <p className="error">Campo requerido</p>
                          )}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </DetailChildSection>

              <DetailChildSection title="Datos académicos">
                {nivelesFields?.filter((a) => a.nivelId == 2).length > 0 && (
                  <div>
                    <label>
                      <strong>Estudios secundarios</strong>
                    </label>
                    {nivelesFields?.map(
                      (item, index) =>
                        item.nivelId == 2 && (
                          <div
                            className="grid-container col-3 col-lg-3 col-md-3 col-sm-12 border-bottom"
                            key={index}
                          >
                            <input type="hidden" value={item.nivelId} />
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Pais</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.paisId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.paises?.map((item, i) => {
                                    return (
                                      <option key={i} value={item.paisId}>
                                        {item.paisNombre}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.personasEstudios?.[index]?.paisId && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]?.paisId
                                        .message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Estado</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.estadoId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.estados?.map((item, i) => {
                                    return (
                                      <option key={i} value={item.estadoId}>
                                        {item.estadoNombre}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.personasEstudios?.[index]?.estadoId && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.estadoId.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Centro educativo</label>
                                <i className="material-icons important"></i>
                                <div className="select-async-container">
                                  <SelectAsync
                                    tipo={1}
                                    height={30}
                                    disabled={!inputsHabilitados}
                                    control={control}
                                    name={`personasEstudios.${index}.centroEducativoId`}
                                    rules={{
                                      validate: validateCentro(index),
                                    }}
                                    paisId={personasEstudios[index].paisId}
                                    valueDefault={
                                      personasEstudios[index].centroEducativoId
                                    }
                                    centroEducativo={
                                      personasEstudios[index].centroEducativo
                                    }
                                    nivelId={item.nivelId}
                                  />
                                </div>
                                <span className="spanInfo">
                                  Escriba el centro educativo
                                </span>
                                {errors.personasEstudios?.[index]
                                  ?.centroEducativoId?.type === 'validate' && (
                                  <p className="error">Campo requerido</p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Título obtenido</label>
                                <Required />
                                <input
                                  type="text"
                                  className={
                                    inputsHabilitados == true
                                      ? ' form-control'
                                      : 'disabled form-control'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.tituloObtenido`,
                                    { required: 'Campo requerido' }
                                  )}
                                />
                                {errors.personasEstudios?.[index]
                                  ?.tituloObtenido && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.tituloObtenido.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item-col-2">
                              <div className="form-group">
                                <label>Año conclusión</label>
                                <i className="material-icons important"></i>
                                <input
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  type="number"
                                  {...register(
                                    `personasEstudios.${index}.fechaConclusion`,
                                    { required: 'Campo requerido' }
                                  )}
                                />
                                {errors.personasEstudios?.[index]
                                  ?.fechaConclusion && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.fechaConclusion.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )}

                {nivelesFields?.filter((a) => a.nivelId == 3).length > 0 && (
                  <div>
                    <label>
                      <strong>Estudios universitarios y posteriores</strong>
                    </label>
                    {nivelesFields?.map(
                      (item, index) =>
                        item.nivelId == 3 && (
                          <div
                            className="grid-container col-4 col-lg-4 col-md-4 col-sm-12 border-bottom"
                            key={index}
                          >
                            <input type="hidden" value={item.nivelId} />
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Pais</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.paisId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.paises?.map((item, i) => {
                                    return (
                                      <option key={i} value={item.paisId}>
                                        {item.paisNombre}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.personasEstudios?.[index]?.paisId && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]?.paisId
                                        .message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Estado</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.estadoId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.estados?.map((item, i) => {
                                    return (
                                      <option key={i} value={item.estadoId}>
                                        {item.estadoNombre}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.personasEstudios?.[index]?.estadoId && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.estadoId.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="grid-item">
                              <div className="form-group">
                                <label>Centro educativo</label>
                                <i className="material-icons important"></i>
                                <div className="select-async-container">
                                  <SelectAsync
                                    tipo={4}
                                    height={30}
                                    disabled={!inputsHabilitados}
                                    control={control}
                                    name={`personasEstudios.${index}.centroEducativoId`}
                                    rules={{
                                      validate: validateCentro(index),
                                    }}
                                    paisId={personasEstudios[index].paisId}
                                    valueDefault={
                                      personasEstudios[index].centroEducativoId
                                    }
                                    centroEducativo={
                                      personasEstudios[index].centroEducativo
                                    }
                                    nivelId={item.nivelId}
                                  />
                                </div>
                                <span className="spanInfo">
                                  Escriba el centro educativo
                                </span>
                                {errors.personasEstudios?.[index]
                                  ?.centroEducativoId?.type === 'validate' && (
                                  <p className="error">Campo requerido</p>
                                )}
                              </div>
                            </div>

                            <div className="grid-item">
                              <div className="form-group">
                                <label>Título obtenido</label>
                                <Required />
                                <input
                                  type="text"
                                  className={
                                    inputsHabilitados == true
                                      ? ' form-control'
                                      : 'disabled form-control'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.tituloObtenido`,
                                    { required: 'Campo requerido' }
                                  )}
                                />
                                {errors.personasEstudios?.[index]
                                  ?.tituloObtenido && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.tituloObtenido.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="grid-item">
                              <div className="form-group">
                                <label>Año conclusión</label>
                                <i className="material-icons important"></i>
                                <input
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  type="number"
                                  {...register(
                                    `personasEstudios.${index}.fechaConclusion`,
                                    { required: 'Campo requerido' }
                                  )}
                                />
                                {errors.personasEstudios?.[index]
                                  ?.fechaConclusion && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]
                                        ?.fechaConclusion.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item-col-2">
                              <div className="form-group">
                                <label>Grado obtenido</label>
                                <Required />
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasEstudios.${index}.gradoId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.personasEstudiosGrados?.map(
                                    (item, i) => {
                                      return (
                                        <option key={i} value={item.gradoId}>
                                          {item.gradoNombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors.personasEstudios?.[index]?.gradoId && (
                                  <p className="error">
                                    {
                                      errors?.personasEstudios?.[index]?.gradoId
                                        .message
                                    }
                                  </p>
                                )}
                              </div>

                              <div className="action-btn">
                                <button
                                  className={
                                    inputsHabilitados == true
                                      ? 'button-text'
                                      : 'button-text disabled'
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    nivelesRemove(index);
                                  }}
                                >
                                  <i className="material-icons"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )}

                <div>
                  <label>Apoyo durante los estudios</label>
                  <div className="check-container flex">
                    <div className="form-control-check flex">
                      <input
                        type="radio"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        value={true}
                        checked={convertToBoolean(teniaApoyoAcademico) == true}
                        {...register('teniaApoyoAcademico')}
                      />
                      <label>Si</label>
                    </div>
                    <div className="form-control-check flex">
                      <input
                        type="radio"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        value={false}
                        checked={convertToBoolean(teniaApoyoAcademico) == false}
                        {...register('teniaApoyoAcademico')}
                      />
                      <label>No</label>
                    </div>
                    {convertToBoolean(teniaApoyoAcademico) && (
                      <>
                        <input
                          className={
                            convertToBoolean(
                              solicitudDetalle?.teniaApoyoAcademico
                            )
                              ? 'disabled'
                              : ''
                          }
                          id="inputAlergia"
                          placeholder="Especifique (Si)"
                          type="text"
                          name="apoyoEstudiosNombre"
                          {...register('apoyoEstudiosNombre')}
                          disabled={
                            convertToBoolean(teniaApoyoAcademico) !== true
                          }
                        />
                        {errors?.apoyoEstudiosNombre?.type === 'validate' && (
                          <p className="error">Campo requerido</p>
                        )}
                      </>
                    )}
                    {errors?.teniaApoyoAcademico?.type === 'validate' && (
                      <p className="error">Campo requerido</p>
                    )}
                  </div>
                  {fields.length > 0 && (
                    <div>
                      <label>Manejo de idiomas</label>
                      <div className="infoLanguage">
                        {fields?.map((item, index) => (
                          <div key={'el_' + index}>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Idioma</label>
                                <i className="material-icons important"></i>
                                <input
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  type="text"
                                  {...register(
                                    `personasIdiomas.${index}.idiomaNombre`,
                                    { required: 'Campo requerido' }
                                  )}
                                />
                                {errors.personasIdiomas?.[index]
                                  ?.idiomaNombre && (
                                  <p className="error">
                                    {
                                      errors?.personasIdiomas?.[index]
                                        ?.idiomaNombre.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="grid-item">
                              <div className="form-group">
                                <label>Lectura</label>
                                <i className="material-icons important"></i>
                                <select
                                  name={`personasIdiomas.${index}.nivelLecturaId`}
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasIdiomas.${index}.nivelLecturaId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden>Seleccione</option>
                                  {solicitudDetalle?.personasIdiomaNiveles?.map(
                                    (item, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={item.nivelIdiomaId}
                                        >
                                          {item.nivelIdiomaNombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors.personasIdiomas?.[index]
                                  ?.nivelLecturaId && (
                                  <p className="error">
                                    {
                                      errors?.personasIdiomas?.[index]
                                        ?.nivelLecturaId.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Escritura</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasIdiomas.${index}.nivelEscrituraId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value={''}>
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.personasIdiomaNiveles?.map(
                                    (item, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={item.nivelIdiomaId}
                                        >
                                          {item.nivelIdiomaNombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors.personasIdiomas?.[index]
                                  ?.nivelEscrituraId && (
                                  <p className="error">
                                    {
                                      errors?.personasIdiomas?.[index]
                                        ?.nivelEscrituraId.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item">
                              <div className="form-group">
                                <label>Habla</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasIdiomas.${index}.nivelHablaId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.personasIdiomaNiveles?.map(
                                    (item, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={item.nivelIdiomaId}
                                        >
                                          {item.nivelIdiomaNombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors.personasIdiomas?.[index]
                                  ?.nivelHablaId && (
                                  <p className="error">
                                    {
                                      errors?.personasIdiomas?.[index]
                                        ?.nivelHablaId.message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="grid-item-col-2">
                              <div className="form-group">
                                <label>MCER</label>
                                <i className="material-icons important"></i>
                                <select
                                  className={
                                    inputsHabilitados == true ? '' : 'disabled'
                                  }
                                  {...register(
                                    `personasIdiomas.${index}.idiomaMarcoComunEuropeoId`,
                                    { required: 'Campo requerido' }
                                  )}
                                >
                                  <option hidden value="">
                                    Seleccione
                                  </option>
                                  {solicitudDetalle?.nivelesMarcoComunEuropeo?.map(
                                    (item, i) => {
                                      return (
                                        <option
                                          key={i}
                                          value={item.marcoComunEuropeoId}
                                        >
                                          {item.marcoComunEuropeoNombre}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                                {errors.personasIdiomas?.[index]
                                  ?.idiomaMarcoComunEuropeoId && (
                                  <p className="error">
                                    {
                                      errors?.personasIdiomas?.[index]
                                        ?.idiomaMarcoComunEuropeoId.message
                                    }
                                  </p>
                                )}
                              </div>
                              <div className="d-flex pt-12">
                                <>
                                  <button
                                    className={
                                      inputsHabilitados == true
                                        ? 'button-text'
                                        : 'button-text disabled'
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      remove(index);
                                    }}
                                  >
                                    <i className="material-icons"></i>
                                  </button>
                                </>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </DetailChildSection>
            </DetailMainSection>

            <DetailMainSection title="Datos de la solicitud">
              <DetailChildSection title="Documentos de inscripción">
                {solicitudDetalle?.perfilNombre != null && (
                  <span className="sub-title d-block">
                    Perfil: {solicitudDetalle?.perfilNombre}
                  </span>
                )}
                <table className="table-archivos">
                  <thead>
                    <tr>
                      <th>Tipo de documento</th>
                      <th>Tipos permitidos</th>
                      <th>Entregado</th>
                      <th>Prórroga</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitudDetalle?.documentos?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.tipoDocumento}
                          {item.esObligatorio && (
                            <i className="material-icons"></i>
                          )}
                        </td>
                        <td>{item.tiposPermitidos}</td>
                        <td>
                          {item.entregado ? (
                            <>
                              <i className="material-icons success"></i>
                            </>
                          ) : (
                            <>
                              <i className="material-icons danger"></i>
                            </>
                          )}
                        </td>
                        <td>
                          {item.prorroga ? (
                            <>
                              {item.prorroga}{' '}
                              <button
                                onClick={(e) => {
                                  abrirModalProrroga(item);
                                }}
                                className="button-text"
                              >
                                Remover
                              </button>
                              <button
                                onClick={(e) => {
                                  handleProrrogaClick(e, item, true);
                                }}
                                className="button-text"
                              >
                                Editar
                              </button>
                            </>
                          ) : (
                            <>-</>
                          )}
                        </td>
                        <td>
                          {item.prorroga == null ? (
                            <div className="upload-btn-wrapper div-relative">
                              <button
                                onClick={(e) => {
                                  handleProrrogaClick(e, item);
                                }}
                                className="btn"
                              >
                                Otorgar prórroga
                              </button>
                            </div>
                          ) : null}
                          {item.entregado ? (
                            <div
                              className="flex-form"
                              style={{ justifyContent: 'center' }}
                            >
                              {solicitudDetalle.estadoId == 9 && (
                                <UploadBtn
                                  title={<i className="material-icons"></i>}
                                  handleFile={(el) => {
                                    handleFileUploadTable(el, item);
                                  }}
                                  accepted={item.tiposPermitidos}
                                />
                              )}
                              {item.entregado && (
                                <>
                                  {!item.esObligatorio &&
                                    (roles?.includes(Rol.DIRECTOR) ||
                                      roles?.includes(Rol.REGISTRO) ||
                                      roles?.includes(Rol.ADMIN)) && (
                                      <button
                                        onClick={() => {
                                          confirmarEliminarArchivo(
                                            item,
                                            'general'
                                          );
                                        }}
                                        className="modBtn"
                                      >
                                        <i className="material-icons"></i>
                                      </button>
                                    )}
                                  <FileContainer
                                    verArchivo={() => {
                                      verArchivo(
                                        getArchivo(
                                          item.perfilId,
                                          item.tipoDocumentoId
                                        ).archivoId
                                      );
                                    }}
                                    decargarArchivo={() => {
                                      let ar = getArchivo(
                                        item.perfilId,
                                        item.tipoDocumentoId
                                      );
                                      downloadFile(
                                        ar.archivoId,
                                        ar.archivoNombre
                                      );
                                    }}
                                    loading={loadingFiles}
                                    handleOpen={(e) => {
                                      showFile(
                                        true,
                                        getArchivo(
                                          item.perfilId,
                                          item.tipoDocumentoId
                                        )?.archivoContenido,
                                        getArchivo(
                                          item.perfilId,
                                          item.tipoDocumentoId
                                        )?.tipo
                                      );
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </DetailChildSection>
              {roles?.includes(Rol.oficialAdmisiones) ||
              roles?.includes(Rol.REGISTRO) ||
              roles?.includes(Rol.ADMIN) ? (
                <DetailChildSection title="Documentos administrativos">
                  <table className="table-archivos">
                    <thead>
                      <tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {solicitudDetalle?.archivosAdministrativos?.length > 0 ? (
                        solicitudDetalle?.archivosAdministrativos?.map(
                          (item, index) => (
                            <tr key={item.archivoId}>
                              <td>{item.archivoNombre}</td>
                              <td>{item.contentType}</td>
                              <td>
                                <div className="flex-form">
                                  {roles?.includes(Rol.REGISTRO) && (
                                    <button
                                      className="modBtn text-primary "
                                      onClick={(e) => {
                                        e.preventDefault();
                                        editDocName(item);
                                      }}
                                    >
                                      <i className="material-icons status-primary">
                                        
                                      </i>
                                    </button>
                                  )}

                                  <FileContainer
                                    verArchivo={() => {
                                      verArchivo(item.archivoId);
                                    }}
                                    decargarArchivo={() => {
                                      downloadFile(
                                        item.archivoId,
                                        item.archivoNombre
                                      );
                                    }}
                                    loading={loadingFiles}
                                    handleOpen={(e) => {
                                      showFile(
                                        true,
                                        getArchivo(
                                          item.perfilId,
                                          item.tipoDocumentoId
                                        )?.archivoContenido
                                      );
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <h3 className="text-grey">
                              No se han agregado documentos
                            </h3>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="btnGroupAction">
                    <button
                      className="btnBlue"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowFileModalOthers(true);
                      }}
                    >
                      Agregar documento
                    </button>
                  </div>
                </DetailChildSection>
              ) : null}

              <DetailChildSection title="Información principal">
                {solicitudDetalle?.perfilNombre != null && (
                  <span className="sub-title d-block" style={{ width: '100%' }}>
                    Perfil: {solicitudDetalle?.perfilNombre}
                  </span>
                )}
                <div>
                  <label>Nivel</label>
                  <select
                    onInput={handleNivelChange}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('nivelId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.carrerasNiveles?.map((item, i) => {
                      return (
                        <option key={i} value={item.nivelId}>
                          {item.nivelAcademicoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.nivelId && (
                    <p className="error">{errors.nivelId.message}</p>
                  )}
                </div>
                <div>
                  <label>Carrera a matricular</label>
                  <select
                    onInput={handleCarreraChange}
                    defaultValue={carreraId}
                    className={
                      inputsHabilitados === true
                        ? 'input-large'
                        : 'disabled input-large'
                    }
                    {...register('carreraId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option value="">Seleccione</option>
                    {carrerasByNiveles?.map((item, i) => {
                      return (
                        <option key={i} value={item.carreraId}>
                          {item.carreraNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.carreraId && (
                    <p className="error">{errors.carreraId.message}</p>
                  )}
                </div>
                <div>
                  <label>Año de la carrera</label>
                  <input
                    type="number"
                    defaultValue={curriculumAnio}

                    disabled
                    {...register('curriculumAnio', {
                      required: 'Campo requerido',
                    })}
                  />
                  {errors.curriculumAnio && (
                    <p className="error">{errors.curriculumAnio.message}</p>
                  )}
                </div>
                <div>
                  <label>Periodo de la carrera</label>
                  <select
                    defaultValue={curriculumId}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('curriculumId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option value="">Seleccione</option>
                    {curriculums?.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.periodoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.curriculumId && (
                    <p className="error">{errors.curriculumId.message}</p>
                  )}
                </div>
                <div>
                  <label>Sesión de la carrera</label>
                  <select
                    onInput={reloadBecas}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    defaultValue={sesionId}
                    {...register('curriculumSesionId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option value="">Seleccione</option>
                    {sesiones?.map((item, i) => {
                      return (
                        <option key={i} value={item.sesionId}>
                          {item.sesionNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.curriculumSesionId && (
                    <p className="error">{errors.curriculumSesionId.message}</p>
                  )}
                </div>
                <div>
                  <label>¿Cuándo desea iniciar?</label>
                  <input
                    type="text"
                    disabled
                    {...register('fechaInicio', {
                      required: 'Campo requerido',
                    })}
                  />
                  {errors.fechaInicio && (
                    <p className="error">{errors.fechaInicio.message}</p>
                  )}
                </div>
                <div>
                  <label>Año de ingreso</label>
                  <input
                    type="number"
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    onInput={handleAnioChange}
                    {...register('anio', {
                      required: 'Campo requerido',
                      validate: validarAnio,
                    })}
                  />
                  {errors.anio && (
                    <p className="error">{errors.anio.message}</p>
                  )}
                </div>
                <div>
                  <label>Periodo de ingreso</label>
                  <select
                    onInput={reloadBecas}
                    defaultValue={periodoId}
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    {...register('periodoId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option value="">Seleccione</option>
                    {periodos?.map((item, i) => {
                      return (
                        <option key={i} value={item.periodoId}>
                          {item.periodoNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.periodoId && (
                    <p className="error">{errors.periodoId.message}</p>
                  )}
              </div>
                {periodoPrincipal?.tipoPeriodoId === 2 && (
                <div>
                  <label>Tipo de ingreso</label>
                  <select
                    onInput={reloadBecas}
                    defaultValue={tipoIngresoId}
                    className={inputsHabilitados ? '' : 'disabled'}
                    {...register('TipoIngresoId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option value="">Seleccione</option>
                    {tiposPeriodosIngreso?.map((a, i) => {
                        return (
                          <option key={i} value={a.tipoIngresoId}>
                            {a.tipoNombre}
                          </option>
                        );
                      })}
                  </select>
                  {errors.periodoId && (
                    <p className="error">{errors.periodoId.message}</p>
                  )}
                </div>
                )}
              </DetailChildSection>
              <DetailChildSection title="Becas y descuentos">
                <div>
                  <label>Becas disponibles</label>
                  <select
                    onInput={handleBecasChange}
                    defaultValue={becaId}
                    className={
                      inputsHabilitados === true
                        ? 'input-large becas-select'
                        : 'disabled input-large becas-select'
                    }
                    {...register('becaId')}
                  >
                    <option value="">Seleccione</option>
                    {becas?.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.becaId && (
                    <p className="error">{errors.becaId.message}</p>
                  )}
                </div>
                <div>
                  <label>Nivel</label>
                  <select
                    className={
                      inputsHabilitados == true
                        ? 'becas-select'
                        : 'disabled becas-select'
                    }
                    {...register('levelId')}
                  >
                    <option value="">Seleccione</option>
                    {nivelesBecas?.map((item, i) => {
                      return (
                        <option key={i} value={item.levelId}>
                          {item.level}
                        </option>
                      );
                    })}
                  </select>
                  {errors.levelId && (
                    <p className="error">{errors.levelId.message}</p>
                  )}
                </div>
                <div className="multiFile-container">
                  <div className="file-list">
                    {solicitudDetalle?.becasArchivos?.map((item, i) => (
                      <FileInfo
                        descargar={(id, name) => downloadFile(id, name)}
                        verImagen={(id) => verArchivo(id)}
                        item={item}
                        onClick={(val) => {
                          confirmarEliminarArchivo(item);
                        }}
                        key={i}
                      />
                    ))}
                  </div>
                  {(roles?.includes(Rol.DIRECTOR) ||
                    roles?.includes(Rol.ADMIN) ||
                    usuarioId === atendidoPor) && (
                    <div className="btnGroupAction">
                      <button
                        className="btnBlue"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowFileModalScholarShip(true);
                          setEsBeca(true);
                        }}
                      >
                        Subir archivos
                      </button>
                    </div>
                  )}
                </div>
              </DetailChildSection>

              <DetailChildSection title="Patrocinios">
                <div>
                  <label>Patrocinadores</label>
                  <select
                    onInput={reloadPatrocinio}
                    defaultValue={patrocinadorId}
                    className={
                      inputsHabilitados === true
                        ? 'input-large becas-select'
                        : 'disabled input-large becas-select'
                    }
                    {...register('patrocinadorId')}
                  >
                    <option value="">Seleccione</option>
                    {patrocinadores?.map((item, i) => {
                      return (
                        <option key={i} value={item.patrocinadorCode}>
                          {item.patrocinadorNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.patrocinadorId && (
                    <p className="error">{errors.patrocinadorId.message}</p>
                  )}
                </div>
                <div>
                  <label>Patrocinio</label>
                  <select
                    value={patrocinioId}
                    className={
                      inputsHabilitados == true
                        ? ' becas-select'
                        : 'disabled becas-select'
                    }
                    {...register('patrocinioId')}
                  >
                    <option value="">Seleccione</option>
                    {patrocinios?.map((item, i) => {
                      return (
                        <option key={i} value={item.patrocinioId}>
                          {item.patrocinioNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.patrocinioId && (
                    <p className="error">{errors.patrocinioId.message}</p>
                  )}
                </div>
                <div className="multiFile-container">
                  <div className="file-list">
                    {solicitudDetalle?.patrociniosArchivos?.map((item, i) => (
                      <FileInfo
                        descargar={(id, name) => downloadFile(id, name)}
                        verImagen={(id) => verArchivo(id)}
                        item={item}
                        onClick={(val) => {
                          confirmarEliminarArchivo(item);
                        }}
                        key={i}
                      />
                    ))}
                  </div>

                  {(roles?.includes(Rol.DIRECTOR) ||
                    roles?.includes(Rol.ADMIN) ||
                    usuarioId === atendidoPor) && (
                    <div className="btnGroupAction">
                      <button
                        className="btnBlue"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowFileModalScholarShip(true);
                          setEsBeca(false);
                        }}
                      >
                        Subir archivos
                      </button>
                    </div>
                  )}
                </div>
              </DetailChildSection>
              <DetailChildSection title="Información adicional">
                <div>
                  <label>Experiencia con educación virtual</label>
                  <div className="check-container">
                    <div className="form-control-check flex ">
                      <input
                        type="radio"
                        value={'Nunca he llevado cursos virtuales.'}
                        name="experienciaEducacionVirtual"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        onClick={() =>
                          cambiarExperiencia(
                            'Nunca he llevado cursos virtuales.'
                          )
                        }
                        checked={
                          experienciaEducacion ===
                          'Nunca he llevado cursos virtuales.'
                        }
                        {...register('experienciaEducacionVirtual')}
                      />
                      <label>Nunca he llevado cursos virtuales.</label>
                    </div>

                    <div className="form-control-check flex">
                      <input
                        type="radio"
                        value={'Si, con Zoom, Teams, Meet y similares'}
                        name="experienciaEducacionVirtual"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        onClick={() =>
                          cambiarExperiencia(
                            'Si, con Zoom, Teams, Meet y similares'
                          )
                        }
                        checked={
                          experienciaEducacion ===
                          'Si, con Zoom, Teams, Meet y similares'
                        }
                        {...register('experienciaEducacionVirtual')}
                      />
                      <label>Si, con Zoom, Teams, Meet y similares.</label>
                    </div>

                    <div className="form-control-check flex">
                      <input
                        type="radio"
                        value={'Si, en modalidad 100% virtual'}
                        name="experienciaEducacionVirtual"
                        className={inputsHabilitados == true ? '' : 'disabled'}
                        onClick={() =>
                          cambiarExperiencia('Si, en modalidad 100% virtual')
                        }
                        checked={
                          experienciaEducacion ===
                          'Si, en modalidad 100% virtual'
                        }
                        {...register('experienciaEducacionVirtual')}
                      />
                      <label>Si, en modalidad 100% virtual.</label>
                    </div>
                  </div>
                </div>
                <div>
                  <label>Se enteró de CENFOTEC por:</label>
                  <select
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    name="formaConocernosId"
                    {...register('formaConocernosId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.formasConocernos?.map((item, i) => {
                      return (
                        <option key={i} value={item.formaConocernosId}>
                          {item.formaNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.formaConocernosId && (
                    <p className="error">{errors.formaConocernosId.message}</p>
                  )}
                </div>
                {!mayoriaEdad && (
                  <>
                    <div className="grid-item">
                      <div className="form-group">
                        <i className="material-icons important"></i>
                        <label>
                          Padre, madre, representante legal o persona encargada
                        </label>
                        <input
                          {...register('encargadoLegalNombre', {
                            required: 'Campo requerido',
                          })}
                          className={
                            inputsHabilitados == true ? '' : 'disabled'
                          }
                          type="text"
                        />
                        {errors.encargadoLegalNombre && (
                          <p className="error">
                            {errors.encargadoLegalNombre.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>Teléfono</label>
                        <i className="material-icons important"></i>
                        <input
                          {...register('encargadoLegalTelefono', {
                            required: 'Campo requerido',
                          })}
                          className={
                            inputsHabilitados == true ? '' : 'disabled'
                          }
                          type="text"
                        />
                        {errors.encargadoLegalTelefono && (
                          <p className="error">
                            {errors.encargadoLegalTelefono.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="grid-item">
                      <div className="form-group">
                        <label>No. de identificación</label>
                        <i className="material-icons important"></i>
                        <input
                          {...register('encargadoLegalDni', {
                            required: 'Campo requerido',
                          })}
                          className={
                            inputsHabilitados == true ? '' : 'disabled'
                          }
                          type="text"
                        />
                        {errors.encargadoLegalDni && (
                          <p className="error">
                            {errors.encargadoLegalDni.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="grid-item">
                      {solicitudDetalle.responsableDniAdjunto != null && (
                        <div>
                          <label>Responsable Dni</label>
                          <FileContainer
                            loading={loadingFiles}
                            verArchivo={() => {
                              verArchivo(
                                solicitudDetalle.responsableDniAdjunto.archivoId
                              );
                            }}
                            decargarArchivo={() => {
                              downloadFile(
                                solicitudDetalle.responsableDniAdjunto
                                  .archivoId,
                                solicitudDetalle.responsableDniAdjunto
                                  .archivoNombre
                              );
                            }}
                            handleFile={(el) => {
                              handleFileUpload(el, 'responsableDniAdjunto');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="grid-item">
                  {solicitudDetalle.firma != null && (
                    <div>
                      <label>Firma</label>
                      <FileContainer
                        loading={loadingFiles}
                        verArchivo={() => {
                          verArchivo(solicitudDetalle.firma.archivoId);
                        }}
                        decargarArchivo={() => {
                          downloadFile(
                            solicitudDetalle.firma.archivoId,
                            solicitudDetalle.firma.archivoNombre
                          );
                        }}
                        handleFile={(el) => {
                          handleFileUpload(el, 'firma');
                        }}
                      />
                    </div>
                  )}
                </div>
                <div>
                  <label>Razón para elegir CENFOTEC</label>
                  <select
                    className={inputsHabilitados == true ? '' : 'disabled'}
                    name="razonElegirnosId"
                    {...register('razonElegirnosId', {
                      required: 'Campo requerido',
                    })}
                  >
                    <option hidden value="">
                      Seleccione
                    </option>
                    {solicitudDetalle?.razonesElegirnos?.map((item, i) => {
                      return (
                        <option key={i} value={item.razonElegirnosId}>
                          {item.razonNombre}
                        </option>
                      );
                    })}
                  </select>
                  {errors.razonElegirnosId && (
                    <p className="error">{errors.razonElegirnosId.message}</p>
                  )}
                </div>
              </DetailChildSection>
            </DetailMainSection>

            {requiereVerificacionSicobatec ? (
              <DetailMainSection
                title="Verificación en Sicobatec"
                isInitialOpened={false}
              >
                <div className="dataContainer d-block ma-auto">
                  {loadingSicobatec ? (
                    <div className="align-spinner">
                      <LoadingSpiner
                        styles={{
                          width: '45px',
                          height: '45px',
                          border: '6px solid #bbbbbb',
                          borderTop: '6px solid #035cc9',
                        }}
                      />
                    </div>
                  ) : personasSicobatec.length > 0 ? (
                    <>
                      <span className="modal-info-text">
                        Seleccione el campo con el que coincida
                      </span>
                      <div className="table-container">
                        <table className="table-adm">
                          <thead>
                            <tr>
                              <td>Coincidencia</td>
                              <td>Nombre</td>
                              <td>Cédula</td>
                              <td>Región educativa</td>
                              <td>Institución educativa</td>
                              <td>Tipo título</td>
                              <td>Modalidad</td>
                              <td>Tomo</td>
                              <td>Folio</td>
                              <td>Asiento</td>
                              <td>Número título</td>
                              <td>Año</td>
                              <td></td>
                            </tr>
                          </thead>

                          <tbody>
                            {personasSicobatec?.map((item, i) => {
                              return (
                                <tr key={item.id}>
                                  <td>
                                    <input
                                      type="radio"
                                      name="coincide"
                                      value={true}
                                      defaultChecked={item.coincide == true}
                                      onChange={() => selectPersona(item)}
                                    />
                                  </td>
                                  <td>
                                    {item.nombres} {item.apellidos}
                                  </td>
                                  <td>{item.cedula}</td>
                                  <td>{item.regionEducativa}</td>
                                  <td>{item.institucionEducativa}</td>
                                  <td>{item.tipoTitulo}</td>
                                  <td>{item.modalidad}</td>
                                  <td>{item.tomo}</td>
                                  <td>{item.folio}</td>
                                  <td>{item.asiento}</td>
                                  <td>{item.noTitulo}</td>
                                  <td>{item.anio}</td>
                                  <td>
                                    <a
                                      className="download-icon"
                                      role={'button'}
                                      onClick={() =>
                                        downloadFile(
                                          item.archivo.archivoId,
                                          item.archivo.archivoNombre
                                        )
                                      }
                                    >
                                      <i className="material-icons"></i>
                                    </a>
                                    <a
                                      className="download-icon"
                                      role={'button'}
                                      onClick={() => {
                                        verArchivo(item.archivo.archivoId);
                                      }}
                                    >
                                      <i className="material-icons"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        {personasSicobatec.length > 0 && (
                          <button
                            type="submit"
                            className="button-text"
                            onClick={actualizarComprobacion}
                          >
                            Guardar
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="data-empty">
                      <span>No se encontraron coincidencias</span>

                      <span className="sub-title d-block mt-2">
                        Pais de estudio:{' '}
                        {solicitudDetalle?.paisEstudiosObtenidos}
                      </span>
                      <span className="sub-title d-block">
                        Título obtenido:{' '}
                        {solicitudDetalle?.personaTituloObtenido}
                      </span>
                      <div className="inline-check">
                        <input
                          value={true}
                          checked={validadoManualmente}
                          onChange={(e) => {
                            setValidadoManualmente(true);
                            setVerificarDespuesSicobatec(false);
                            setNoAplicaSicobatec(false);
                          }}
                          disabled={atendidoPor === null}
                          type="checkbox"
                        />
                        <label>Validado en Sicobatec manualmente</label>
                        {solicitudDetalle?.validadoManualmenteEnSicobatec && (
                          <>
                            {solicitudDetalle?.archivoSicobatecManual !=
                            null ? (
                              <>
                                <FileInfo
                                  descargar={(id, name) =>
                                    downloadFile(id, name)
                                  }
                                  verImagen={(id) => verArchivo(id)}
                                  item={{
                                    archivoId:
                                      solicitudDetalle?.archivoSicobatecManualId,
                                    archivoNombre:
                                      solicitudDetalle?.archivoSicobatecManual,
                                  }}
                                  onClick={(val) => {
                                    confirmarEliminarArchivoSicobatec({
                                      archivoId:
                                        solicitudDetalle?.archivoSicobatecManualId,
                                      archivoNombre:
                                        solicitudDetalle?.archivoSicobatecManual,
                                    });
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div className="ml-2 btnGroupAction">
                                  <UploadBtn
                                    title={'Subir archivo'}
                                    handleFile={(el) => {
                                      subirArchivoSicobatecManual(el);
                                    }}
                                    accepted={'.jpg, .jpeg, .png, .pdf'}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="inline-check">
                        <input
                          value={true}
                          checked={verificarDespuesSicobatec}
                          onChange={(e) => {
                            setVerificarDespuesSicobatec(true);
                            setValidadoManualmente(false);
                            setNoAplicaSicobatec(false);
                          }}
                          disabled={atendidoPor === null}
                          type="checkbox"
                        />
                        <label>Validar en Sicobatec después</label>
                      </div>
                      <div className="inline-check">
                        <input
                          value={true}
                          checked={noAplicaSicobatec}
                          onChange={(e) => {
                            setNoAplicaSicobatec(true);
                            setVerificarDespuesSicobatec(false);
                            setValidadoManualmente(false);
                          }}
                          disabled={atendidoPor === null}
                          type="checkbox"
                        />
                        <label>No aplica</label>
                      </div>
                      {atendidoPor != null &&
                      (validadoManualmente !=
                        solicitudDetalle?.validadoManualmenteEnSicobatec ||
                        verificarDespuesSicobatec !=
                          solicitudDetalle?.verificarDespuesSicobatec ||
                        noAplicaSicobatec !=
                          solicitudDetalle?.noAplicaSicobatec) ? (
                        <button
                          className="button-text"
                          onClick={(e) =>
                            validarSicobatecManualmente(e, cargarSolicitud)
                          }
                        >
                          Guardar
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>
              </DetailMainSection>
            ) : null}
          </div>
        </form>
      </main>
    </>
  );
}

export default DetalleSolicitud;
