import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  obtenerPatrocinadores,
  obtenerNivelesBecas,
  obtenerBecas,
  obtenerPatrocinios,
  agregarBecaAdm,
  agregarPatrocinioAdm,
  obtenerSolicitudesBecasPatrocinios,
} from '../../redux/solicitudesAdmSlice';
import WindowDimension from '../../Hooks/WindowDimension';
import { OrderTable } from '../../Components/OrderTable';
import Required from '../../Components/Required';
import useSubmit from '../../Hooks/useSubmit';
import Pagination from './Paginacion';
import LoadingSpiner from '../../Components/LoadingSpiner';

const columns = [
  { label: 'Solicitud no.', accessor: 'numero', sortable: true },
  { label: 'Nombre', accessor: 'nombreCompleto', sortable: true },
  { label: 'Fecha', accessor: 'fechaCreacion', sortable: true },
  { label: 'Carrera', accessor: 'carreraNombre', sortable: true },
  { label: 'Atendido por', accessor: 'atendidoPorUsuario', sortable: true },
  { label: 'Sesión', accessor: 'sesionNombre', sortable: true },
  { label: 'Acción', accessor: 'action', sortable: false },
];

export default function ListaBecasPatrocinios(props) {
  const [rowCount, setRowCount] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedItem, selectItem] = useState();
  const [itemAssigned, setItemAssigned] = useState(null);

  const [patrocinadores, setPatrocinadores] = useState();
  const [patrocinios, setPatrocinios] = useState();

  const [becas, setBecas] = useState();
  const [niveles, setNiveles] = useState();

  const [becaId, setBecaId] = useState();
  const [patrocinadorId, setPatrocinadorId] = useState();
  const [modalState, changeModalState] = useState(false);

  const dispatch = useDispatch();
  const busqueda = useRef();

  const { submitAction } = useSubmit();

  const { solicitudesBecasPatrocinios, loadingSolicitudesBecasPatrocinios } = useSelector(
    (state) => state.solicitudesAdm
  );

  const { width } = WindowDimension();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();

  const cargarSolicitudes = useCallback((filtro = '') => {
    dispatch(obtenerSolicitudesBecasPatrocinios({ filtro, rowCount, page: currentPage }));
  }, [currentPage, rowCount])

  useEffect(() => {
    cargarSolicitudes();
  }, [cargarSolicitudes]);


  useEffect(() => {
    dispatch(obtenerPatrocinadores()).then((res) => {
      if (Array.isArray(res.payload)) setPatrocinadores(res.payload);
      else setPatrocinadores([]);
    });
  }, []);

  useEffect(() => {
    if (selectedItem) {
      dispatch(
        obtenerBecas({
          periodo: selectedItem?.periodoId,
          session:
            selectedItem?.sesionNombre === 'Colones'
              ? 'UNICA'
              : selectedItem?.sesionNombre.toUpperCase(),
          anio: selectedItem?.anio,
        })
      ).then((res) => {
        if (Array.isArray(res.payload)) setBecas(res.payload);
      });
    }
  }, [selectedItem, dispatch]);

  useEffect(() => {
    if (patrocinadorId && selectItem) {
      dispatch(
        obtenerPatrocinios({
          periodo: selectedItem?.periodoId,
          anio: selectedItem?.anio,
          patrocinador: patrocinadorId,
          session: selectedItem?.sesionNombre,
        })
      ).then((res) => {
        if (Array.isArray(res.payload)) setPatrocinios(res.payload);
        else setPatrocinios([]);
      });
    }
  }, [patrocinadorId]);

  useEffect(() => {
    if (becaId)
      dispatch(obtenerNivelesBecas(becaId)).then((res) => {
        if (Array.isArray(res.payload)) setNiveles(res.payload);
        else setNiveles([]);
      });
  }, [becaId]);


  const closeModal = () => {
    changeModalState(false);
    reset({
      patrocinadorId: null,
      becaId: null,
      patrocinioId: null,
      nivelId: null,
    });
  };
  const openModal = (solicitud, itemType) => {
    setItemAssigned(itemType);
    selectItem(solicitud);
    changeModalState(true);
  };

  const asignarBecaPatrocinio = (data) => {
    let d;
    if (data.patrocinioId) {
      d = {
        patrocinioId: +data.patrocinioId,
        patrocinadorId: data.patrocinadorId,
      };
    } else {
      d = { becaId: data.becaId, nivelId: data.nivelId };
    }
    submitAction(
      data.patrocinadorId ? agregarPatrocinioAdm : agregarBecaAdm,
      { ...d, solicitudId: selectedItem.solicitudId },
      () => {
        cargarSolicitudes();
        closeModal();
      }
    );
  };

  return (
    <main className="main-admin">
      <div className="main-admin-bar">
        <h2>Solicitudes disponibles para becas y patrocinios</h2>
        <div className="select-filter-section-ml-auto"></div>
        <div className="header-admin-bar-items ml-auto">
          <label hidden>Búsqueda</label>
          <input
            type="input"
            placeholder="Búsqueda"
            ref={busqueda}
            className="form-control"
            onChange={(e) => cargarSolicitudes(e.target.value)}
          />
        </div>
      </div>

      <div className="table-container relative-class">
        {loadingSolicitudesBecasPatrocinios && (
          <div className="loading-background">
            <LoadingSpiner
              show
              styles={{
                top: '45%',
                left: '50%',
                width: '50px',
                height: '50px',
                position: 'absolute',
                borderWidth: '10px',
                borderStyle: 'solid',
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderColor: 'rgb(27 78 147) rgb(0 0 0) rgb(0 0 0)',
                borderImage: 'initial',
              }}
            />
          </div>
        )}
        {solicitudesBecasPatrocinios.result?.length > 0 ? (
          <>
            <OrderTable
              {...{ columns }}
              data={solicitudesBecasPatrocinios.result ?? []}
              clase="table-adm"
              renderActionItems={(item) => {
                return (
                  <td key={Math.random()}>
                    {item?.becaId == null && (
                      <button
                        className="modBtn text-primary "
                        onClick={() => {
                          openModal(item, 'beca');
                        }}
                      >
                        <i className="material-icons"></i>
                        Beca
                      </button>
                    )}
                    {item?.patrocinioId == null && (
                      <button
                        className="modBtn text-primary "
                        onClick={() => {
                          openModal(item, 'patrocinio');
                        }}
                      >
                        <i className="material-icons"></i>
                        Patrocinio
                      </button>
                    )}
                  </td>
                );
              }}
            />
          </>
        ) : (
          <div className="no-results-container">
            <img src={props.img} alt="Imagen sin resultados" />
            <h1>{props.noResultMessage}</h1>
          </div>
        )}
      </div>
      <Pagination
        rowCount={rowCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onRowCountChange={setRowCount}
        totalPages={solicitudesBecasPatrocinios.pageCount ?? 1}
      />
      <Modal
        estado={modalState}
        cambiarEstado={closeModal}
        titulo="Asignar beca o patrocinio"
        clase={`${width < 576 ? 'full-width' : 'medium'}`}
        buttonGroup={
          <>
            <button
              type="submit"
              className="button-text"
              onClick={handleSubmit(asignarBecaPatrocinio)}
            >
              Guardar
            </button>
          </>
        }
      >
        <form>
          <div className="grid-container col-2">
            {itemAssigned === 'beca' ? (
              <>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Beca</label>
                    <Required />
                    <select
                      {...register('becaId')}
                      className="form-control"
                      onChange={(e) => setBecaId(e.target.value)}
                    >
                      <option value="">Seleccione</option>
                      {becas?.map((item, i) => {
                        return (
                          <option key={i} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.becaId && (
                      <p className="error">{errors.becaId.message}</p>
                    )}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Nivel</label>
                    <Required />
                    <select {...register('nivelId')} className="form-control">
                      <option value="">Seleccione</option>
                      {niveles?.map((item, i) => {
                        return (
                          <option key={i} value={item.levelId}>
                            {item.level}
                          </option>
                        );
                      })}
                    </select>
                    {errors.nivelId && (
                      <p className="error">{errors.nivelId.message}</p>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Patrocinador</label>
                    <Required />
                    <select
                      {...register('patrocinadorId')}
                      className="form-control"
                      onChange={(e) => setPatrocinadorId(e.target.value)}
                    >
                      <option value="">Seleccione</option>
                      {patrocinadores?.map((item, i) => {
                        return (
                          <option key={i} value={item.patrocinadorCode}>
                            {item.patrocinadorNombre}
                          </option>
                        );
                      })}
                    </select>
                    {errors.patrocinadorId && (
                      <p className="error">{errors.patrocinadorId.message}</p>
                    )}
                  </div>
                </div>
                <div className="grid-item">
                  <div className="form-group">
                    <label>Patrocinio</label>
                    <Required />
                    <select
                      {...register('patrocinioId')}
                      className="form-control"
                    >
                      <option value="">Seleccione</option>
                      {patrocinios?.map((item, i) => {
                        return (
                          <option key={i} value={item.patrocinioId}>
                            {item.patrocinioNombre}
                          </option>
                        );
                      })}
                    </select>
                    {errors.patrocinadorId && (
                      <p className="error">{errors.patrocinadorId.message}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </Modal>
    </main>
  );
}
